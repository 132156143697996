import styled from 'styled-components';


export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 80%;
  top: 50px;
`;
