const style = {
  '@primary-color': '#1d9ea5',
  fonts: {
    primary: 'SansForgetica-Regular',
  }
};

module.exports = {
  style,
}
